<template>
  <div class="mx-4" id="contracts-tracker">
    <div>
      <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

      <ContractEditor
        ref="contractModal"
        @contractUpdated="contractUpdated"
        :contractId="modalContractId"
      ></ContractEditor>

      <b-container fluid>
        <div>
          <h1 class="mw-50 mx-5">
            Contract Tracker
            <b-button
              variant="outline-secondary"
              class="float-right mt-2"
              @click="$refs.contractModal.show(null)"
            >
              <font-awesome-icon :icon="['fas', 'plus']" /> New contract
            </b-button>

            <b-button
              v-if="filteredContracts.length > 0"
              variant="outline-primary"
              class="float-right mt-2 mr-2"
              @click="exportExcel()"
            >
              <font-awesome-icon :icon="['fal', 'file-excel']" class="mr-1" /> Export Excel
            </b-button>
          </h1>
        </div>

        <!-- BACKEND FILLTERS -->
        <b-row class="backend-filters mx-5 px-2 py-3">
          <b-col cols="12" xl="7">
            <b-form-group label="Signed Date " label-cols="2" class="mb-1 small">
              <div class="d-flex justify-content-between align-items-center">
                <b-input-group size="sm" class="mt-2">
                  <template #prepend>
                    <b-input-group-text>From</b-input-group-text>
                  </template>
                  <b-input
                    id="Signed date from"
                    :disabled="isSigned"
                    size="sm"
                    v-model="signedDateStart"
                    type="date"
                    @input="isValidDate('Signed date from')"
                    @mouseleave="isValidDate('Signed date from')"
                  ></b-input>
                </b-input-group>
                <b-input-group size="sm" class="mt-2 ml-2">
                  <template #prepend>
                    <b-input-group-text>To</b-input-group-text>
                  </template>

                  <b-input
                    id="Signed date to"
                    @input="isValidDate('Signed date to')"
                    @mouseleave="isValidDate('Signed date to')"
                    :disabled="isSigned"
                    size="sm"
                    v-model="signedDateEnd"
                    type="date"
                  ></b-input>
                </b-input-group>

                <b-button
                  variant="outline-secondary"
                  size="sm"
                  :class="'ml-2 mt-2 d-flex all-time-checkbox' + (isSigned ? ' checked' : '')"
                  @click="
                    isSigned = !isSigned;
                    autoloadDates();
                  "
                >
                  All time
                </b-button>
              </div>
            </b-form-group>

            <b-form-group label="Re-signed Date " label-cols="2" class="mb-1 small">
              <div class="d-flex justify-content-between align-items-center">
                <b-input-group size="sm" class="mt-2">
                  <template #prepend>
                    <b-input-group-text>From</b-input-group-text>
                  </template>
                  <b-input
                    id="Re-signed date from"
                    :disabled="isResigned"
                    size="sm"
                    v-model="resignedDateStart"
                    type="date"
                    @input="isValidDate('Re-signed date from')"
                    @mouseleave="isValidDate('Re-signed date from')"
                  ></b-input>
                </b-input-group>
                <b-input-group size="sm" class="mt-2 ml-2">
                  <template #prepend>
                    <b-input-group-text>To</b-input-group-text>
                  </template>

                  <b-input
                    id="Re-signed date to"
                    @input="isValidDate('Re-signed date to')"
                    @mouseleave="isValidDate('Re-signed date to')"
                    :disabled="isResigned"
                    size="sm"
                    v-model="resignedDateEnd"
                    type="date"
                  ></b-input>
                </b-input-group>

                <b-button
                  variant="outline-secondary"
                  size="sm"
                  :class="'ml-2 mt-2 d-flex all-time-checkbox' + (isResigned ? ' checked' : '')"
                  @click="
                    isResigned = !isResigned;
                    autoloadDates();
                  "
                >
                  All time
                </b-button>
              </div>
            </b-form-group>

            <b-form-group label="Objection Date " label-cols="2" class="mb-1 small">
              <div class="d-flex justify-content-between align-items-center">
                <b-input-group size="sm" class="mt-2">
                  <template #prepend>
                    <b-input-group-text>From</b-input-group-text>
                  </template>
                  <b-input
                    id="Objection date from"
                    :disabled="isObjection"
                    size="sm"
                    v-model="objectionDateStart"
                    type="date"
                    @input="isValidDate('Objection date from')"
                    @mouseleave="isValidDate('Objection date from')"
                  ></b-input>
                </b-input-group>
                <b-input-group size="sm" class="mt-2 ml-2">
                  <template #prepend>
                    <b-input-group-text>To</b-input-group-text>
                  </template>

                  <b-input
                    id="Objection date to"
                    @input="isValidDate('Objection date to')"
                    @mouseleave="isValidDate('Objection date to')"
                    :disabled="isObjection"
                    size="sm"
                    v-model="objectionDateEnd"
                    type="date"
                  ></b-input>
                </b-input-group>

                <b-button
                  variant="outline-secondary"
                  size="sm"
                  :class="'ml-2 mt-2 d-flex all-time-checkbox' + (isObjection ? ' checked' : '')"
                  @click="
                    isObjection = !isObjection;
                    autoloadDates();
                  "
                >
                  All time
                </b-button>
              </div>
            </b-form-group>

            <b-form-group label="Failed Date" label-cols="2" class="mb-1 small">
              <div class="d-flex justify-content-between align-items-center">
                <b-input-group size="sm" class="mt-2">
                  <template #prepend> <b-input-group-text>From</b-input-group-text> </template>
                  <b-input
                    id="Failed date from"
                    @input="isValidDate('Failed date from')"
                    @mouseleave="isValidDate('Failed date from')"
                    :disabled="isFailed"
                    size="sm"
                    type="date"
                    v-model="failedDateStart"
                  ></b-input>
                </b-input-group>
                <b-input-group size="sm" class="mt-2 ml-2">
                  <template #prepend> <b-input-group-text>To</b-input-group-text> </template>
                  <b-input
                    id="Failed date to"
                    @input="isValidDate('Failed date to')"
                    @mouseleave="isValidDate('Failed date to')"
                    size="sm"
                    type="date"
                    :disabled="isFailed"
                    v-model="failedDateEnd"
                  ></b-input>
                </b-input-group>
                <b-button
                  variant="outline-secondary"
                  size="sm"
                  :class="'ml-2 mt-2 d-flex all-time-checkbox' + (isFailed ? ' checked' : '')"
                  @click="
                    isFailed = !isFailed;
                    autoloadDates();
                  "
                >
                  All time
                </b-button>
              </div>
            </b-form-group>

            <b-form-group label="Dead Date" label-cols="2" class="mb-1 small">
              <div class="d-flex justify-content-between align-items-center">
                <b-input-group size="sm" class="mt-2">
                  <template #prepend> <b-input-group-text>From</b-input-group-text> </template>
                  <b-input
                    id="Dead date from"
                    @input="isValidDate('Dead date from')"
                    @mouseleave="isValidDate('Dead date from')"
                    :disabled="isDead"
                    size="sm"
                    type="date"
                    v-model="deadDateStart"
                  ></b-input>
                </b-input-group>
                <b-input-group size="sm" class="mt-2 ml-2">
                  <template #prepend> <b-input-group-text>To</b-input-group-text> </template>
                  <b-input
                    id="Dead date to"
                    @input="isValidDate('Dead date to')"
                    @mouseleave="isValidDate('Dead date to')"
                    size="sm"
                    type="date"
                    :disabled="isDead"
                    v-model="deadDateEnd"
                  ></b-input>
                </b-input-group>
                <b-button
                  variant="outline-secondary"
                  size="sm"
                  :class="'ml-2 mt-2 d-flex all-time-checkbox' + (isDead ? ' checked' : '')"
                  @click="
                    isDead = !isDead;
                    autoloadDates();
                  "
                >
                  All time
                </b-button>
              </div>
            </b-form-group>

            <b-form-group label="End Date " label-cols="2" class="mb-1 small">
              <div class="d-flex justify-content-between align-items-center">
                <b-input-group size="sm" class="mt-2">
                  <template #prepend>
                    <b-input-group-text>From</b-input-group-text>
                  </template>

                  <b-input
                    id="End date from"
                    @input="isValidDate('End date from')"
                    @mouseleave="isValidDate('End date from')"
                    :disabled="isEnd"
                    size="sm"
                    v-model="endDateStart"
                    type="date"
                  ></b-input>
                </b-input-group>
                <b-input-group size="sm" class="mt-2 ml-2">
                  <template #prepend>
                    <b-input-group-text>To</b-input-group-text>
                  </template>

                  <b-input
                    id="End date to"
                    @input="isValidDate('End date to')"
                    @mouseleave="isValidDate('End date to')"
                    :disabled="isEnd"
                    size="sm"
                    v-model="endDateEnd"
                    type="date"
                  ></b-input>
                </b-input-group>
                <b-button
                  variant="outline-secondary"
                  size="sm"
                  :class="'ml-2 mt-2 d-flex all-time-checkbox' + (isEnd ? ' checked' : '')"
                  @click="
                    isEnd = !isEnd;
                    autoloadDates();
                  "
                >
                  All time
                </b-button>
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12" xl="5">
            <b-form-group label="Comm. Gross " label-cols="3" class="mb-1 small">
              <div class="d-flex">
                <b-input-group size="sm" class="mt-2">
                  <template #prepend>
                    <b-input-group-text>From</b-input-group-text>
                  </template>
                  <b-input size="sm" v-model="commGrossStart" type="number"></b-input>
                </b-input-group>

                <b-input-group size="sm" class="mt-2 ml-2">
                  <template #prepend>
                    <b-input-group-text>To</b-input-group-text>
                  </template>
                  <b-input size="sm" v-model="commGrossEnd" type="number"></b-input>
                </b-input-group>
              </div>
            </b-form-group>

            <multiselect
              v-model="selects.selectedStates"
              :options="states"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Select state"
              class="mt-3"
            >
            </multiselect>

            <multiselect
              v-model="selects.selectedProducts"
              :options="products"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Select product type"
              label="text"
              track-by="value"
              class="mt-3"
            >
            </multiselect>
          </b-col>
        </b-row>

        <div class="backend-filters mx-5 px-3 pb-3 d-flex justify-content-between">
          <div>
            <p class="invalid-date m-0" v-if="invalidDateMessage">
              <font-awesome-icon class="mx-1" :icon="['fas', 'exclamation-triangle']" />
              {{ invalidDateMessage }}
            </p>
          </div>

          <div>
            <b-button variant="outline-secondary" class="mr-2" size="sm" @click="clearFilters()">
              Clear Filters
            </b-button>
            <b-button
              :disabled="invalidDateMessage != null"
              variant="primary"
              @click="fetchData"
              size="sm"
            >
              Search
            </b-button>
          </div>
        </div>

        <!-- FRONTEND FILLTERS -->
        <b-row class="frontend-filters mx-5 px-2 py-3">
          <b-col cols="12"> <p class="m-0">Result filters</p> </b-col>
          <b-col cols="12" md="4">
            <b-input
              size="sm"
              class="my-2"
              placeholder="Accountant Firm"
              v-model="frontendFilters.accountantFirm"
            ></b-input>
            <b-input
              size="sm"
              class="my-2"
              placeholder="Accountant Email"
              v-model="frontendFilters.accountantEmail"
            ></b-input>
          </b-col>
          <b-col cols="12" md="4"
            ><b-input
              size="sm"
              class="my-2"
              placeholder="New Supplier Name"
              v-model="frontendFilters.newSupplierName"
            ></b-input>
            <b-input
              size="sm"
              class="my-2"
              placeholder="Old Supplier Name"
              v-model="frontendFilters.oldSupplierName"
            ></b-input>
          </b-col>
          <b-col cols="12" md="4">
            <b-input
              size="sm"
              class="my-2"
              placeholder="Organisation Name"
              v-model="frontendFilters.organisationName"
            ></b-input>
            <b-input
              size="sm"
              class="my-2"
              placeholder="Supply Name"
              v-model="frontendFilters.supplyName"
            ></b-input>
            <b-input
              size="sm"
              class="my-2"
              placeholder="Meter Number"
              v-model="frontendFilters.meterNumber"
            ></b-input>
          </b-col>
        </b-row>

        <!-- PAGINATION -->
        <div class="pagination-row mx-5 mt-4">
          <p class="m-0">
            <span class="mr-2">Contracts count: {{ filteredContracts.length }}</span>
            <span
              class="mr-2"
              v-b-popover.hover="'Sum of, if available, initial commission, otherwise commission.'"
            >
              Contracts&nbsp;Commission: {{ formatPoundToDisplay(commissionSum) }}
            </span>
            <span v-b-popover.hover="'LIVE + LOCKED_IN + SIGNED commission'" class="mr-2">
              Adjusted&nbsp;Commission: {{ formatPoundToDisplay(adjustedCommissionSum) }}
            </span>
          </p>
          <div>
            <b-pagination
              class="mb-2 ml-3"
              v-show="filteredContracts.length > perPage"
              v-model="currentPage"
              :total-rows="filteredContracts.length"
              :per-page="perPage"
            ></b-pagination>
            <p class="m-0 d-inline-block float-right">
              Contracts / page:
              <span class="ml-1 d-inline-block">
                <span v-for="number in [50, 100, 500]" :key="number">
                  <span v-if="perPage == number">{{ number }}</span>
                  <b-link v-else @click="perPage = number">{{ number }} </b-link>,
                </span>

                <span v-if="perPage == filteredContracts.length">All</span>
                <b-link v-else @click="perPage = filteredContracts.length">All</b-link>
              </span>
            </p>
          </div>
        </div>

        <!-- TABLE FIELDS SELECTION -->
        <DynamicTableHeader
          @updated-fields="updateTableFields"
          :allFields="possibleFields"
          :fields="fields"
          :defaultFields="defaultFields"
        />

        <!-- TABLE -->
        <b-table
          class="mx-2"
          id="contracts-table"
          busy.sync="loading"
          show-empty
          :items="filteredContracts"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-desc="true"
          :sort-by="'createdDate'"
        >
          <template slot="empty">
            <div class="text-center">
              <Busy v-if="loading" primary text="Loading..." />
              <p v-else-if="searchRan">No contracts found.</p>
              <p v-else>Search for contracts to show.</p>
            </div>
          </template>

          <template v-slot:cell(actionButton)="row">
            <b-dropdown lazy variant="outline-secondary">
              <b-dropdown-item
                @click="
                  modalContractId = row.item.contractId;
                  $refs.contractModal.show(row.item.contractId);
                "
              >
                <font-awesome-icon :icon="['far', 'edit']" class="text-muted mr-2" />Edit Contract
              </b-dropdown-item>
              <b-dropdown-item
                :to="{
                  name: 'contract-payments',
                  params: { contractId: row.item.contractId }
                }"
                target="_blank"
              >
                <font-awesome-icon :icon="['fas', 'pound-sign']" class="text-muted mr-2" />View
                Payments
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="
                  !row.item.organisationId || busyFreshsalesOrgName == row.item.organisationName
                "
                @click="openFreshsalesDeal(row.item.organisationName)"
              >
                <font-awesome-icon :icon="['fas', 'external-link']" class="text-muted mr-2" />
                Open in Freshsales
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                :disabled="!row.item.organisationId"
                :to="{
                  name: 'organisation-details',
                  params: { organisationId: row.item.organisationId }
                }"
              >
                <font-awesome-icon
                  :icon="['fas', 'info-circle']"
                  class="text-muted mr-2"
                />Organisation Details
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="!row.item.organisationId"
                :to="{
                  name: 'savings',
                  params: { organisationId: row.item.organisationId }
                }"
              >
                <font-awesome-icon :icon="['fas', 'pound-sign']" class="text-muted mr-2" />Savings
                Report
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="!row.item.organisationId"
                :to="{
                  name: 'savings-editor',
                  params: {
                    organisationId: row.item.organisationId,
                    latestSupply: [
                      row.item.product != null ? row.item.product.toUpperCase() : row.item.product,
                      {}
                    ]
                  }
                }"
              >
                <font-awesome-icon :icon="['fas', 'unicorn']" class="text-muted mr-1" />Savings
                Editor
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="!row.item.organisationId"
                :to="{
                  name: 'spotlight',
                  params: { organisationId: row.item.organisationId }
                }"
              >
                <font-awesome-icon :icon="['fas', 'whale']" class="text-muted mr-2" />Spotlight
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template v-slot:cell(docusignContractURL)="row">
            <div class="d-flex flex-wrap">
              <b-button
                class="m-1"
                v-for="url in row.item.docusignContractURL"
                :key="url"
                size="sm"
                variant="outline-primary"
                target="_blank"
                :href="url"
                v-b-popover.hover.top="extractDSContractID(url)"
              >
                <font-awesome-icon :icon="['fal', 'file']" size="sm" />
              </b-button>
            </div>
          </template>

          <template v-slot:cell(resignedDates)="row">
            <div v-if="row.item.resignedDates && row.item.resignedDates.length > 0">
              <span>
                {{ formatDate(row.item.resignedDates[row.item.resignedDates.length - 1]) }}
              </span>
              <p
                class="resigned-times d-block m-0"
                v-if="row.item.resignedDates.length > 1"
                v-b-popover.hover.bottom="listDates(row.item.resignedDates)"
              >
                Re-signed {{ row.item.resignedDates.length }} times
              </p>
            </div>
          </template>

          <template v-slot:cell(objectionDates)="row">
            <div v-if="row.item.objectionDates && row.item.objectionDates.length > 0">
              <span>
                {{ formatDate(row.item.objectionDates[row.item.objectionDates.length - 1]) }}
              </span>
              <p
                class="resigned-times d-block m-0"
                v-if="row.item.objectionDates.length > 1"
                v-b-popover.hover.bottom="listDates(row.item.objectionDates)"
              >
                Objection {{ row.item.objectionDates.length }} times
              </p>
            </div>
          </template>

          <template v-slot:cell(signedDate)="row">
            <span> {{ formatDate(row.item.signedDate) }} </span>
          </template>
        </b-table>

        <!-- PAGINATION -->
        <div class="mx-5 mt-4 float-right">
          <b-pagination
            class="mb-2 ml-3 text-end"
            v-show="filteredContracts.length > perPage"
            v-model="currentPage"
            :total-rows="filteredContracts.length"
            :per-page="perPage"
          ></b-pagination>

          <p class="m-0 float-right" v-if="filteredContracts.length > 0">
            Contracts / page:
            <span class="ml-1 d-inline-block">
              <span v-for="number in [50, 100, 500]" :key="number">
                <span v-if="perPage == number">{{ number }}</span>
                <b-link v-else @click="perPage = number">{{ number }} </b-link>,
              </span>

              <span v-if="perPage == filteredContracts.length">All</span>
              <b-link v-else @click="perPage = filteredContracts.length">All</b-link>
            </span>
          </p>
        </div>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#contracts-tracker {
  .resigned-times,
  .objection-times {
    font-size: 13px;
  }

  .invalid-date {
    border: 1px solid $color-grey-lighter2;
    border-radius: 15px;
    padding: 0.4em 1em 0.4em 1em;
    font-size: 14px;
  }

  .form-row legend {
    padding-top: 0.75rem;
    padding-bottom: 0;
  }

  #contracts-table {
    tr:hover {
      background-color: $color-grey-lighter9;
    }
    td {
      vertical-align: middle;
    }
  }

  .backend-filters {
    background-color: $color-grey-lighter4;

    .all-time-checkbox {
      width: 110px !important;
      font-size: 11px;
      &.checked {
        color: white !important;
        background-color: $color-font-para !important;
      }
    }

    .product-badge {
      border: 1px solid $color-grey-lighter2;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .frontend-filters {
    border: 2px solid $color-grey-lighter4;
  }

  .pagination-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import { EventBus } from "@/components/eventbus";
import FormatHelper from "@/helper/formathelper";
import SupplyTypes from "@/helper/supplytypehelper";
import Busy from "@/components/Busy";
import ContractEditor from "@/components/contracts/ContractEditor";
import DynamicTableHeader from "@/components/DynamicTableHeader.vue";

export default {
  name: "ContractsTracker",
  components: { Busy, ContractEditor, DynamicTableHeader },
  data() {
    return {
      title: "Contracts Tracker",
      loading: false,
      invalidDateMessage: null,
      contracts: [],
      contractsMap: {},
      modalContractId: null,
      searchRan: false,

      //TABLE
      perPage: 50,
      currentPage: 1,
      possibleFields: {
        //CONTRACT
        oldXeroId: { label: "Old Xero ID" },
        contractId: { label: "Contract ID" },
        paymentFromId: { label: "Counterparty ID" },
        paymentFromName: { label: "Counterparty" },
        createdDate: { label: "Created Date", formatter: "formatDate" },
        createdByEmail: { label: "Created By Email" },
        cpmEmail: { label: "CPM" },
        apmEmail: { label: "APM" },
        startDate: { label: "Start Date", formatter: "formatDate" },
        endDate: { label: "End Date", formatter: "formatDate" },
        contractLength: { label: "Contr. Length (Months)" },
        accountantUserId: { label: "Accountant ID" },
        accountantFirm: { label: "Accountant Firm" },
        accountantEmail: { label: "Accountant Email" },
        accountantSignUpDate: { label: "First user sign-up date", formatter: "formatDate" },
        docusignContractURL: { label: "DocuSign URL" },

        //STATE
        state: { label: "State" },
        outDate: { label: "Out Date", formatter: "formatDate" },
        signedDate: { label: "Signed Date", formatter: "formatDate" },
        resignedDates: { label: "Re-signed Date" },
        objectionDates: { label: "Objection Date" },
        failedDate: { label: "Failed Date", formatter: "formatDate" },
        deadDate: { label: "Dead Date", formatter: "formatDate" },
        reasonFailed: { label: "Reason Failed" },

        //DEAL
        organisationId: { label: "Org. ID" },
        organisationName: { label: "Org. Name", formatter: "orgShortName" },
        organisationCreatedDate: { label: "Org. Created Date", formatter: "formatDate" },
        supplyId: { label: "Supply ID" },
        supplyName: { label: "Supply Name" },
        product: { label: "Product", formatter: "supplyTypeDisplay" },
        meterNumber: { label: "Meter Number" },
        oldSupplierId: { label: "Old Supplier ID" },
        oldSupplierName: { label: "Old Supplier Name" },
        newSupplierId: { label: "New Supplier ID" },
        newSupplierName: { label: "New Supplier Name" },
        oldAnnualCost: { label: "Old Annual Cost", formatter: "formatPoundToDisplay" },
        newAnnualCost: { label: "New Annual Cost", formatter: "formatPoundToDisplay" },
        annualSavings: { label: "Annual Savings", formatter: "formatPoundToDisplay" },
        savingsOverContract: { label: "Savings Over Contr.", formatter: "formatPoundToDisplay" },
        commissionGross: { label: "Comm. Gross", formatter: "formatPoundToDisplay" },
        commissionNet: { label: "Comm. Net", formatter: "formatPoundToDisplay" },
        commissionLevel: { label: "Comm. Level" },
        consumption: { label: "AQ", formatter: "formatNumberToDisplay" },
        initialCommissionGross: { label: "Init. Comm. Gross", formatter: "formatPoundToDisplay" },
        initialCommissionLevel: { label: "Init. Comm. Level" },
        initialConsumption: { label: "Init. AQ", formatter: "formatNumberToDisplay" }
      },
      fields: [{ key: "actionButton", label: "", class: "text-center align-middle" }],
      defaultFields: [
        "organisationName",
        "accountantEmail",
        "signedDate",
        "failedDate",
        "commissionGross",
        "product",
        "state"
      ],
      //BACKEND FILTERS
      signedDateStart: null,
      signedDateEnd: null,
      resignedDateStart: null,
      resignedDateEnd: null,
      objectionDateStart: null,
      objectionDateEnd: null,
      endDateStart: null,
      endDateEnd: null,
      failedDateStart: null,
      failedDateEnd: null,
      deadDateStart: null,
      deadDateEnd: null,
      commGrossStart: null,
      commGrossEnd: null,

      isSigned: false,
      isResigned: false,
      isObjection: false,
      isFailed: false,
      isDead: false,
      isEnd: false,

      states: ["NEW", "OUT", "SIGNED", "LOCKED_IN", "FAILED", "DEAD", "LIVE"],
      products: [],
      selects: {
        selectedStates: [],
        selectedProducts: []
      },

      //FRONTEND FILTERS
      frontendFilters: {
        organisationName: null,
        organisationId: null,
        accountantFirm: null,
        accountantEmail: null,
        newSupplierName: null,
        oldSupplierName: null,
        supplyName: null,
        meterNumber: null,
      },

      //FRESHSALES DEALS
      busyFreshsalesOrgName: null
    };
  },

  computed: {
    filteredContracts() {
      let result = this.contracts;
      let resultFiltered = result;
      
      Object.keys(this.frontendFilters).forEach((filterName) => {
        if (this.frontendFilters[filterName] && this.frontendFilters[filterName].length > 1) {
          result = resultFiltered.filter((contracts) => {
            if (contracts[filterName] && typeof contracts[filterName] != "undefined") {
              return contracts[filterName]
                .toLowerCase()
                .includes(this.frontendFilters[filterName].toLowerCase());
            }
          });
        }
        resultFiltered = result;
      });

      return result;
    },

    commissionSum() {
      let result = 0;
      if (!this.isEmpty(this.filteredContracts)) {
        this.filteredContracts.forEach((contract) => {
          if (contract.initialCommissionGross) {
            result += contract.initialCommissionGross;
          } else if (contract.commissionGross) {
            result += contract.commissionGross;
          }
        });
      }
      return result;
    },

    adjustedCommissionSum() {
      let result = 0;
      if (!this.isEmpty(this.filteredContracts)) {
        this.filteredContracts.forEach((contract) => {
          if (
            (contract.state == "LIVE" ||
              contract.state == "LOCKED_IN" ||
              contract.state == "SIGNED") &&
            contract.commissionGross
          ) {
            result += contract.commissionGross;
          }
        });
      }
      return result;
    }
  },

  created() {
    this.getProducts();
    this.getSearchQuery();
  },

  methods: {
    supplyTypes: SupplyTypes.supplyTypes,
    supplyTypeDisplay: SupplyTypes.toDisplay,
    formatDate: FormatHelper.formatDate,
    formatPoundToDisplay: FormatHelper.formatPoundToDisplay,
    formatNumberToDisplay: FormatHelper.formatNumberToDisplay,
    orgShortName: FormatHelper.orgShortName,
    isEmpty: FormatHelper.isEmpty,
    formatDateStringWithTime: FormatHelper.formatDateStringWithTime,

    listDates(dates) {
      let list = "";
      if (dates && dates.length > 0) {
        dates.forEach((date) => {
          if (list == "") {
            list += this.formatDate(date);
          } else {
            list += ", " + this.formatDate(date);
          }
        });
      }
      return list;
    },

    isValidDate(id) {
      this.invalidDateMessage = null;
      let i = document.getElementById(id);

      if (i.validity.badInput == true) {
        this.invalidDateMessage = id + " is not a valid date.";
      }
    },

    updateTableFields(updateFields) {
      this.fields = updateFields;
    },

    extractDSContractID(url) {
      if (url) {
        return url.split("/")[4];
      } else null;
    },

    autoloadDates() {
      let end = new Date().toJSON().slice(0, 10);
      this.signedDateStart = this.isSigned ? "2000-01-01" : null;
      this.signedDateEnd = this.isSigned ? end : null;
      this.resignedDateStart = this.isResigned ? "2000-01-01" : null;
      this.resignedDateEnd = this.isResigned ? end : null;
      this.objectionDateStart = this.isObjection ? "2000-01-01" : null;
      this.objectionDateEnd = this.isObjection ? end : null;
      this.failedDateStart = this.isFailed ? "2000-01-01" : null;
      this.failedDateEnd = this.isFailed ? end : null;
      this.deadDateStart = this.isDead ? "2000-01-01" : null;
      this.deadDateEnd = this.isDead ? end : null;
      this.endDateStart = this.isEnd ? "2000-01-01" : null;
      this.endDateEnd = this.isEnd ? end : null;
    },

    exportExcel() {
      let dateFields = [
        "createdDate",
        "startDate",
        "endDate",
        "resignedDates",
        "objectionDates",
        "signedDate",
        "outDate",
        "failedDate",
        "deadDate",
        "accountantSignUpDate",
        "organisationCreatedDate"
      ];

      if (this.filteredContracts.length > 0) {
        let rows = [],
          columns = [];

        Object.keys(this.possibleFields).forEach((key) => {
          columns.push(key);
        });
        rows.push([...columns]);

        for (let i = 0; i < this.filteredContracts.length; i++) {
          let contract = Object.assign({}, this.filteredContracts[i]);
          columns = [];
          Object.keys(this.possibleFields).forEach((key) => {
            if (contract[key]) {
              let value = contract[key];
              if (
                (key == "resignedDates" ||
                  key == "docusignContractURL" ||
                  key == "objectionDates") &&
                value.length > 0
              ) {
                value = value[value.length - 1];
              }

              if (dateFields.indexOf(key) != -1) {
                if (value) {
                  value = value.slice(0, 10);
                }
              }

              columns.push(value);
            } else {
              columns.push("");
            }
          });

          rows.push([...columns]);
        }

        var lineArray = [];
        rows.forEach(function (infoArray, index) {
          var line = infoArray.join(" \t");
          lineArray.push(index == 0 ? line : line);
        });
        var csvContent = lineArray.join("\r\n");
        var excel_file = document.createElement("a");
        excel_file.setAttribute(
          "href",
          "data:application/vnd.ms-excel;charset=utf-8," + encodeURIComponent(csvContent)
        );
        excel_file.setAttribute("download", "Contracts.xls");
        document.body.appendChild(excel_file);
        excel_file.click();
        document.body.removeChild(excel_file);
      }
    },

    clearFilters() {
      this.invalidDateMessage = null;
      this.signedDateStart = null;
      this.signedDateEnd = null;
      this.resignedDateStart = null;
      this.resignedDateEnd = null;
      this.objectionDateStart = null;
      this.objectionDateEnd = null;
      this.endDateStart = null;
      this.endDateEnd = null;
      this.failedDateStart = null;
      this.failedDateEnd = null;
      this.deadDateStart = null;
      this.deadDateEnd = null;
      this.commGrossStart = null;
      this.commGrossEnd = null;
      this.selects.selectedProducts = [];
      this.selects.selectedStates = [];

      this.isSigned = false;
      this.isResigned = false;
      this.isObjection = false;
      this.isFailed = false;
      this.isDead = false;
      this.isEnd = false;

      //FRONTEND FILTERS
      Object.keys(this.frontendFilters).forEach((key) => {
        this.frontendFilters[key] = null;
      });
    },

    setupQuery() {
      let query =
        `&signedDateStart=${this.formatDateStringWithTime(this.signedDateStart)}` +
        `&signedDateEnd=${this.formatDateStringWithTime(this.signedDateEnd, false)}` +
        `&resignedDateStart=${this.formatDateStringWithTime(this.resignedDateStart)}` +
        `&resignedDateEnd=${this.formatDateStringWithTime(this.resignedDateEnd, false)}` +
        `&objectionDateStart=${this.formatDateStringWithTime(this.objectionDateStart)}` +
        `&objectionDateEnd=${this.formatDateStringWithTime(this.objectionDateEnd, false)}` +
        `&endDateStart=${this.formatDateStringWithTime(this.endDateStart)}` +
        `&endDateEnd=${this.formatDateStringWithTime(this.endDateEnd, false)}` +
        `&failedDateStart=${this.formatDateStringWithTime(this.failedDateStart)}` +
        `&failedDateEnd=${this.formatDateStringWithTime(this.failedDateEnd, false)}` +
        `&deadDateStart=${this.formatDateStringWithTime(this.deadDateStart)}` +
        `&deadDateEnd=${this.formatDateStringWithTime(this.deadDateEnd, false)}` +
        `&commGrossStart=${this.commGrossStart ? this.commGrossStart : ""}` +
        `&commGrossEnd=${this.commGrossEnd ? this.commGrossEnd : ""}`;

      if (
        !this.isEmpty(this.selects.selectedProducts) &&
        this.selects.selectedProducts.length > 0
      ) {
        query += `&products=${this.selects.selectedProducts.map((a) => a.value)}`;
      }

      if (!this.isEmpty(this.selects.selectedStates) && this.selects.selectedStates.length > 0) {
        query += `&states=${this.selects.selectedStates}`;
      }

      return query;
    },
    async fetchData() {
      this.contracts = [];
      this.loading = true;

      try {
        const client = await ApiHelper.http();
        const response = await client.get(`${ApiHelper.endPoint()}contracts?` + this.setupQuery());
        if (response.data.status === "success") {
          this.contractsMap = response.data.contractsMap;
          this.contracts = Object.values(this.contractsMap);
          if (this.contracts && this.contracts.length > 0) {
            this.modalContractId = this.contracts[0].contractId;
          }
        } else {
          this.showMessage("A problem occured in searching for data.", "warning");
        }
      } catch (error) {
        Console.error(error);
        this.showMessage("A problem occured in searching for data.", "warning");
      } finally {
        this.loading = false;
        this.searchRan = true;
      }
    },

    contractUpdated(contractUpdate) {
      this.contractsMap[contractUpdate.contractId] = Object.assign({}, contractUpdate);
      this.contracts = Object.values(this.contractsMap);
    },

    getProducts() {
      let productsArr = SupplyTypes.supplyTypes();
      if (!this.isEmpty(productsArr)) {
        productsArr.forEach((product) => {
          let obj = {};
          obj.value = product;
          obj.text = this.supplyTypeDisplay(product);
          this.products.push(obj);
        });
      }
    },

    async openFreshsalesDeal(orgName) {
      this.busyFreshsalesOrgName = orgName;
      let openedWindow = window.open("about:blank", orgName);
      openedWindow.document.body.innerHTML = "<b>Loading Freshsales deal...</b>";

      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}deals/freshsales?organisationName=` + orgName)
        .then((response) => {
          Console.log(response);
          if (!response.data.freshsalesDealIds.length) {
            this.showMessage("No deals found in Frehsales", "warning");
            openedWindow.close();
          } else {
            window.open(
              "https://reducer.freshsales.io/deals/" + response.data.freshsalesDealIds[0],
              orgName
            );
          }
        })
        .catch((e) => {
          Console.log(e);
          this.showMessage("There was a problem getting Freshsales deal", "warning");
          openedWindow.close();
        })
        .finally(() => (this.busyFreshsalesOrgName = null));
    },

    getSearchQuery() {
      if (this.$route.query.orgId) {
        this.frontendFilters.organisationId = this.$route.query.orgId;
        this.fetchData();
      }
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>
